import type { FeatureInfo } from '@sparky/framework';

export function isFeatureEnabled(featureInfo: FeatureInfo[] | undefined, featureKey: string) {
	return !!featureInfo?.some((f) => f.name === featureKey && f.toggle === 1)
}
export function getEpmConfigRootKey() {
	return 'workflows'
}

export function isEieEnabled() {
	return true
}
export function isFeatureFlagEnabled(
	featureFlagInfo: { name: string; value: boolean }[] | undefined,
	featureKey: string
) {
	return !!featureFlagInfo?.some((f) => f.name === featureKey && f.value === true)
}
export function isOTPEnabled() {
	return true
}
